import React from "react";
import s from "./Default.module.css"
import { useState } from "react";
import ToNews from "../News/ToNews";
import img from "../../assets/News/News100425.png"

const News100425 = ({ children, header }) => {
    const [newsData, setNews] = useState([])

    return <div className={s.page}>
        <h2 style={{
            fontWeight: "normal",
            marginTop: "50px",
            color: "#3a4663",
            textAlign: "left",
            maxWidth: "1100px",
            margin: "40px auto",
            fontFamily: "Proxima Nova Lt"
        }}>
            Сборник работ победителей Всероссийского Конкурса социальной рекламы «Стиль жизни — здоровье! 2024»
        </h2>
        <div>
            <img src={img} />
        </div>
        <div style={{ fontFamily: "Proxima Nova Lt" }}>
            ФГБУ «Центр защиты прав и интересов детей» подготовил сборник работ победителей Всероссийского Конкурса социальной рекламы в области формирования культуры здорового и безопасного образа жизни «Стиль жизни — здоровье! 2024».
            <p></p>
            По итогам федерального этапа 22 работы заняли призовые места. Победителями стали ребята из 19 субъектов Российской Федерации! <a style={{fontWeight: "bold"}} href="https://fcprc.ru/media/media/mediacia/Стиль_жизни_-_Здоровье_2024_Каталог_по_итогам_конкурса_1.pdf">Предлагаем ознакомиться с их творческими проектами.</a>
            <p></p>
            Поздравляем победителей! Благодарим всех участников, а также педагогов и родителей за помощь и поддержку в подготовке конкурсных работ!
            <p></p>
        </div>
        <ToNews />
    </div>
}


export default News100425;